import React, {useState, useEffect, useContext} from "react";
import {AuthContext} from "./AuthContext";
import {Form, Container, Modal} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import Button from "../components/Button";
import {Table, Th, Td, Tr} from "../components/Table";
import CountrySelector from "../components/CountrySelector";
import YearSelector from "../components/YearSelector";
import {useTranslation} from "react-i18next";


function TeamRegister() {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const location = useLocation();

    const team = location.state?.team || {};

    const MAX_PLAYERS = 16;
    const MAX_OFFICIALS = 4;

    const phoneNumberRegex = /^([+0-9\s])*$/;

    const [isModify, setModify] = useState(team !== null ? true : false);

    const [teamName, setTeamName] = useState(team.name || "");
    const [showModal, setShowModal] = useState(false);
    const [showOfficialModal, setShowOfficialModal] = useState(false);
    const [gender, setGender] = useState(team.gender || "");
    const [playerNumber, setPlayerNumber] = useState("");
    const [clubName, setClubName] = useState(team.clubName) || "";
    const [players, setPlayers] = useState(team.players || []);
    const [officials, setOfficials] = useState(team.officials || []);
    const [color1, setColor1] = useState(team.color1 || "");
    const [color2, setColor2] = useState(team.color2 || "");
    const [color3, setColor3] = useState(team.color3 || "");
    const [receipt, setReceipt] = useState(team.receipt || "");

    const [responsibleFirstName, setResponsibleFirstName] = useState(
        team.responsiblePerson?.firstName || ""
    );
    const [responsibleLastName, setResponsibleLastName] = useState(
        team.responsiblePerson?.lastName || ""
    );
    const [responsibleSecondName, setResponsibleSecondName] = useState(
        team.responsiblePerson?.secondName || ""
    );
    const [responsibleTelephone, setResponsibleTelephone] = useState(
        team.responsiblePerson?.telephone || ""
    );
    const [responsibleYearOfBirth, setResponsibleYearOfBirth] = useState(
        team.responsiblePerson?.yearOfBirth || ""
    );
    const [playerYearOfBirth, setPlayerYearOfBirth] = useState("");
    const [playerFirstName, setPlayerFirstName] = useState("");
    const [playerLastName, setPlayerLastName] = useState("");
    const [playerSecondName, setPlayerSecondName] = useState("");
    const [officialFirstName, setOfficialFirstName] = useState("");
    const [officialLastName, setOfficialLastName] = useState("");
    const [officialSecondName, setOfficialSecondName] = useState("");
    const [officialYearOfBirth, setOfficialYearOfBirth] = useState("");
    const [country, setCountry] = useState(team.address?.country || null);
    const [state, setState] = useState(team.address?.state || "");
    const [city, setCity] = useState(team.address?.city || "");

    const [teamType, setTeamType] = useState(team.type || ""); // Add teamType state variable

    const isFormValid = teamName && teamType && gender && country && clubName && responsibleFirstName && responsibleLastName && responsibleTelephone && responsibleYearOfBirth; // Check if all required fields are filled

    const [errorMessage, setErrorMessage] = useState("");
    const [errorMessageOfficial, setErrorMessageOfficial] = useState("");
    const [errorMessagePlayer, setErrorMessagePlayer] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const {username, setUsername} = useContext(AuthContext);
    const { isLoggedIn, setLoggedOut } = useContext(AuthContext);


    useEffect(() => {
        if (!location.state || !location.state.team) {
            // Clear form fields
            setTeamName(""); // Do the same for the other fields...
            setGender("");
            setClubName("");
            setResponsibleFirstName("");
            setResponsibleLastName("");
            setResponsibleSecondName("");
            setResponsibleTelephone("");
            setResponsibleYearOfBirth("");
            setPlayers([]);
            setOfficials([]);
            setColor1("");
            setColor2("");
            setColor3("");
            setCountry("");
            setState("");
            setCity("");
            setTeamType("");
            setReceipt("");

            setModify(false);
        }
    }, [location]);

    const getMinimumPlayers = (teamType) => {
        if (teamType === "NEW_TALENT" || teamType === "SCHOOL") {
            return 5;
        } else {
            return 7;
        }
    };

    const handleTeamNameChange = (event) => {
        setTeamName(event.target.value);
    };

    const handleGenderChange = (event) => {
        setGender(event.target.value);
    };

    const handleClubNameChange = (event) => {
        setClubName(event.target.value);
    };

    const handleResponsibleFirstNameChange = (event) => {
        const value = event.target.value;
        setResponsibleFirstName(value);

    };

    const handleResponsibleLastNameChange = (event) => {
        const value = event.target.value;
        setResponsibleLastName(value);
    };

    const handleResponsibleSecondNameChange = (event) => {
        const value = event.target.value;
        setResponsibleSecondName(value);
    };

    const handleResponsibleTelephoneChange = (event) => {
        const value = event.target.value;

        if (phoneNumberRegex.test(value)) {
            setResponsibleTelephone(value);
        }
    };

    const handleResponsibleYearOfBirthChange = (value) => {
        setResponsibleYearOfBirth(value);
    };

    const handlePlayerNumberChange = (event) => {
        setPlayerNumber(event.target.value);
    };

    const handleColor1Change = (event) => {
        setColor1(event.target.value);
    };

    const handleColor2Change = (event) => {
        setColor2(event.target.value);
    };

    const handleColor3Change = (event) => {
        setColor3(event.target.value);
    };

    const handleCountryChange = (value) => {
        setCountry(value);
    };

    const handleStateChange = (event) => {
        setState(event.target.value);
    };

    const handleCityChange = (event) => {
        setCity(event.target.value);
    };

    const handlePlayerYearOfBirthChange = (value) => {
        setPlayerYearOfBirth(value);
    };

    const handlePlayerFirstNameChange = (event) => {
        const value = event.target.value;
        setPlayerFirstName(value);
    };

    const handlePlayerLastNameChange = (event) => {
        const value = event.target.value;
        setPlayerLastName(value);
    };

    const handlePlayerSecondNameChange = (event) => {
        const value = event.target.value;
        setPlayerSecondName(value);
    };

    const handleOfficialFirstNameChange = (event) => {
        const value = event.target.value;
        setOfficialFirstName(value);
    };

    const handleOfficialLastNameChange = (event) => {
        const value = event.target.value;
        setOfficialLastName(value);
    };

    const handleOfficialSecondNameChange = (event) => {
        const value = event.target.value;
        setOfficialSecondName(value);
    };

    const handleOfficialYearOfBirthChange = (value) => {
        setOfficialYearOfBirth(value);
    };

    const handleTeamTypeChange = (event) => {
        setTeamType(event.target.value);
    };

    const minimumPlayers = getMinimumPlayers(teamType);
    const canSubmit = isFormValid && players.length >= minimumPlayers;

    const handleCancel = () => {
        const confirmed = window.confirm(
            t("confirm.return")
        );

        if (confirmed) {
            navigate("/teams");
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        if (!isFormValid) {
            setErrorMessage(t("form.incomplete"));
            return;
        }

        if (players.length < minimumPlayers) {
            setErrorMessage(
                t("You need to have at least ") + minimumPlayers + t(" players.")
            );
            return;
        }

        try {
            const token = localStorage.getItem("token");
            const method = isModify ? "PATCH" : "POST";
            const url = isModify ? `/api/teams/${team.id}` : "/api/teams";

            const response = await fetch(url, {
                method: method,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`, // Include token in the Authorization header
                },
                body: JSON.stringify({
                    name: teamName,
                    clubName: clubName,
                    address: {
                        country: country,
                        state: state,
                        city: city,
                    },
                    responsiblePerson: {
                        firstName: responsibleFirstName,
                        lastName: responsibleLastName,
                        secondName: responsibleSecondName,
                        telephone: responsibleTelephone,
                        yearOfBirth: responsibleYearOfBirth,
                    },
                    type: teamType, // Include team type
                    color1: color1,
                    color2: color2,
                    color3: color3,
                    gender: gender,
                    players: players,
                    officials: officials,
                    managerName: username,
                    receipt: receipt
                }),
                credentials: "include",
            });

            if (response.ok) {
                console.log("Team registration successful");
                if (isModify) {
                    setSuccessMessage(
                        t("modification.success")
                    );
                } else {
                    setSuccessMessage(
                        t("registration.success.team")
                    );
                }

                setErrorMessage("");

                setTimeout(() => {
                    navigate("/teams");
                }, 5000);
            } else {
                console.error("Team registration failed");
                setErrorMessage(t("registration.failed"));
                setSuccessMessage("");
            }
        } catch (error) {
            console.error("Team registration failed", error);
            setErrorMessage(t("registration.failed"));
            setSuccessMessage("");
        }
    };

    const handleAddPlayer = () => {
        setShowModal(true);
    };

    const handleAddOfficial = () => {
        setShowOfficialModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleOfficialModalClose = () => {
        setShowOfficialModal(false);
    };

    const handleModalSubmit = () => {
        if (!playerFirstName || !playerLastName) {
            setErrorMessagePlayer(
                t("mandatory.name")
            );
            return;
        }

        if (!playerYearOfBirth) {
            setErrorMessagePlayer(t("mandatory.year"));
            return;
        }

        if (playerNumber <= 0 || playerNumber > 99) {
            setErrorMessagePlayer(t("mandatory.player.number"));
            return;
        }

        const playerNumberInt = parseInt(playerNumber, 10);
        if (players.some(player => parseInt(player.number, 10) === playerNumberInt)) {
            setErrorMessagePlayer(t("player.number.exists"));
            return;
        }

        setErrorMessagePlayer("");
        const newPlayer = {
            person: {
                firstName: playerFirstName,
                lastName: playerLastName,
                secondName: playerSecondName,
                yearOfBirth: playerYearOfBirth,
            },
            number: playerNumber,
        };
        setPlayers([...players, newPlayer]);
        setShowModal(false);
        setPlayerFirstName("");
        setPlayerLastName("");
        setPlayerSecondName("");
        setPlayerYearOfBirth("");
        setPlayerNumber("");
    };

    const handleOfficialModalSubmit = () => {
        if (!officialFirstName || !officialLastName) {
            setErrorMessageOfficial(
                t("mandatory.name")
            );
            return;
        }

        if (!officialYearOfBirth) {
            setErrorMessageOfficial(t("mandatory.year"));
            return;
        }

        setErrorMessageOfficial("");
        const newOfficial = {
            firstName: officialFirstName,
            lastName: officialLastName,
            secondName: officialSecondName,
            yearOfBirth: officialYearOfBirth,
        };
        setOfficials([...officials, newOfficial]);
        setShowOfficialModal(false);
        setOfficialFirstName("");
        setOfficialLastName("");
        setOfficialSecondName("");
        setOfficialYearOfBirth("");
    };

    const handleDeletePlayer = (playerIndex) => {
        const newPlayers = players.filter((_, index) => index !== playerIndex);
        setPlayers(newPlayers);
    };

    const handleDeleteOfficial = (officialIndex) => {
        const newOfficials = officials.filter(
            (_, index) => index !== officialIndex
        );
        setOfficials(newOfficials);
    };

    return (
        <Container className="mx-auto my-8 p-6 bg-white rounded-md shadow-md">
            {!isModify && <h1 className="text-2xl">{t('Register team')}</h1>}
            {isModify && <h1 className="text-2xl">{t('Modify team')}</h1>}

            {isLoggedIn ? (

            <Form
                onSubmit={handleSubmit}
                className=" xl:grid xl:grid-cols-5 gap-8 mx-auto"
            >
                <div className="w-full col-span-2">
                    <h3 className="reg-heading">{t('Team details')}</h3>
                    <p className="reg-details-text">
                        {t('registration.details')}
                    </p>

                    <Form.Group
                        controlId="clubName"
                        className="reg-form-group"
                    >
                        <Form.Label className="reg-form-label">{t('Club name')}*:</Form.Label>
                        <Form.Control
                            type="text"
                            value={clubName}
                            onChange={handleClubNameChange}
                            placeholder={t('club.full.name')}
                            className="reg-form-input"
                        />
                    </Form.Group>

                    <Form.Group
                        controlId="teamName"
                        className="reg-form-group"
                    >
                        <Form.Label className="reg-form-label">
                            {t('Team name')}*:
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={teamName}
                            onChange={handleTeamNameChange}
                            placeholder={t('team.full.name')}
                            className="reg-form-input"
                        />
                    </Form.Group>

                    <Form.Group
                        controlId="country"
                        className="flex gap-2 my-4 text-sm justify-start"
                    >
                        <Form.Label className="reg-form-label">{t('Country')}*:</Form.Label>
                        <CountrySelector
                            onChange={handleCountryChange}
                            value={country}
                            className="w-full"
                            placeholder={t('Select your country')}
                        />
                    </Form.Group>
                    <Form.Group
                        controlId="state"
                        className="reg-form-group"
                    >
                        <Form.Label className="reg-form-label">{t('State')}:</Form.Label>
                        <Form.Control
                            type="text"
                            value={state}
                            onChange={handleStateChange}
                            className="reg-form-input"
                            placeholder={t('Enter your state')}
                        />
                    </Form.Group>

                    <Form.Group
                        controlId="city"
                        className="reg-form-group"
                    >
                        <Form.Label className="reg-form-label">{t('City')}:</Form.Label>
                        <Form.Control
                            type="text"
                            value={city}
                            onChange={handleCityChange}
                            className="reg-form-input"
                            placeholder={t('Enter your city')}
                        />
                    </Form.Group>

                    <Form.Group
                        controlId="teamTyp"
                        className="reg-form-group"
                    >
                        <Form.Label className="reg-form-label">{t('Category')}*:</Form.Label>
                        <Form.Control
                            as="select"
                            value={teamType}
                            onChange={handleTeamTypeChange}
                            className="reg-form-input"
                        >
                            <option value="">{t('select.category')}</option>
                            <option value="NEW_TALENT">{t('new.talent')}</option>
                            <option value="SCHOOL">{t('school')}</option>
                            <option value="CHILDREN">{t('children')}</option>
                            <option value="CADET">{t('cadet')}</option>
                            <option value="YOUTH">{t('youth')}</option>
                            <option value="ADULT">{t('adult')}</option>
                            <option value="MASTER">{t('master')}</option>
                        </Form.Control>
                    </Form.Group>

                    <Form.Group
                        controlId="color1"
                        className="reg-form-group"
                    >
                        <Form.Label className="reg-form-label">
                            {t('Primary color')}:
                        </Form.Label>
                        <input
                            type="color"
                            as="select"
                            value={color1}
                            onChange={handleColor1Change}
                            className="reg-form-input"
                        />
                    </Form.Group>

                    <Form.Group
                        controlId="color2"
                        className="reg-form-group"
                    >
                        <Form.Label className="reg-form-label">
                            {t('Secondary color')}:
                        </Form.Label>
                        <input
                            type="color"
                            as="select"
                            value={color2}
                            onChange={handleColor2Change}
                            className="reg-form-input"
                        />
                    </Form.Group>

                    <Form.Group
                        controlId="color3"
                        className="reg-form-group"
                    >
                        <Form.Label className="reg-form-label">
                            {t('Goalkeeper color')}:
                        </Form.Label>
                        <input
                            type="color"
                            as="select"
                            value={color3}
                            onChange={handleColor3Change}
                            className="reg-form-input"
                        />
                    </Form.Group>

                    <Form.Group
                        controlId="gender"
                        className="reg-form-group"
                    >
                        <Form.Label className="reg-form-label">{t('Gender')}*:</Form.Label>
                        <Form.Control
                            as="select"
                            value={gender}
                            onChange={handleGenderChange}
                            className="reg-form-input"
                        >
                            <option value="">{t('select.gender')}</option>
                            {teamType === "NEW_TALENT" ? (
                                <option value="MIXED">{t('mixed')}</option>
                            ) : (
                                <>
                                    <option value="MAN">{t('man')}</option>
                                    <option value="WOMAN">{t('woman')}</option>
                                </>
                            )}
                        </Form.Control>
                    </Form.Group>

                    <h3 className=".reg-heading">{t('Responsible person')}</h3>
                    <p className="reg-details-text">
                        {t('responsible.details')}
                    </p>
                    <Form.Group
                        controlId="responsibleFirstName"
                        className="reg-form-group"
                    >
                        <Form.Label className="reg-form-label">
                            {t('First name')}*:
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={responsibleFirstName}
                            onChange={handleResponsibleFirstNameChange}
                            className="reg-form-input"
                            placeholder={t('Enter your first name')}
                        />
                    </Form.Group>

                    <Form.Group
                        controlId="responsibleSecondName"
                        className="reg-form-group"
                    >
                        <Form.Label className="reg-form-label">
                            {t('Second name')}:
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={responsibleSecondName}
                            onChange={handleResponsibleSecondNameChange}
                            className="reg-form-input"
                            placeholder={t('Enter your second name')}
                        />
                    </Form.Group>

                    <Form.Group
                        controlId="responsibleLastName"
                        className="reg-form-group"
                    >
                        <Form.Label className="reg-form-label">{t('Last name')}*:</Form.Label>
                        <Form.Control
                            type="text"
                            value={responsibleLastName}
                            onChange={handleResponsibleLastNameChange}
                            className="reg-form-input"
                            placeholder={t('Enter your last name')}
                        />
                    </Form.Group>

                    <Form.Group
                        controlId="responsibleTelephone"
                        className="reg-form-group"
                    >
                        <Form.Label className="reg-form-label">{t('Phone number')}*:</Form.Label>
                        <Form.Control
                            type="text"
                            value={responsibleTelephone}
                            onChange={handleResponsibleTelephoneChange}
                            className="reg-form-input"
                            placeholder={t('Enter your phone number')}
                        />
                    </Form.Group>

                    <Form.Group
                        controlId="responsibleYearOfBirth"
                        className="flex gap-2 my-4 text-sm justify-start"
                    >
                        <Form.Label className="reg-form-label">
                            {t('Year of birth')}*:
                        </Form.Label>
                        <YearSelector onChange={handleResponsibleYearOfBirthChange}/>
                    </Form.Group>
                </div>

                <div className="w-full col-span-3">
                    <h3 className=".reg-heading mt-8">{t('Players')}</h3>
                    <p className="reg-details-text">
                        {t('player.details')}
                    </p>

                    <Table>
                        <thead>
                        <Tr>
                            <Th>{t('First name')}</Th>
                            <Th>{t('Second name')}</Th>
                            <Th>{t('Last name')}</Th>
                            <Th>{t('Year of birth')}</Th>
                            <Th>{t('Player number')}</Th>
                            <Th>{t('Actions')}</Th>
                        </Tr>
                        </thead>
                        <tbody>
                        {players.map((p, index) => (
                            <Tr key={index} index={index}>
                                <Td>{p.person.firstName}</Td>
                                <Td>{p.person.secondName}</Td>
                                <Td>{p.person.lastName}</Td>
                                <Td>{p.person.yearOfBirth}</Td>
                                <Td>{p.number}</Td>
                                <Td className="">
                                    <button
                                        className="block font-medium text-sm justify-center align-middle text-red-700 "
                                        type="button"
                                        color="orange"
                                        onClick={() => handleDeletePlayer(index)}
                                    >
                                        {t('Remove')}
                                    </button>
                                </Td>
                            </Tr>
                        ))}
                        </tbody>
                    </Table>

                    <Button
                        type="button"
                        onClick={handleAddPlayer}
                        color="orange"
                        disabled={!isFormValid || players.length >= MAX_PLAYERS}
                    >
                        {t('Add player')} ({players.length}/{MAX_PLAYERS})
                    </Button>

                    <Modal
                        show={showModal}
                        onHide={handleModalClose}
                        className="absolute md:left-0 top-1/4 p-1 lg:top-1/4 w-full lg:left-1/4 lg:w-1/3 lg:p-8 drop-shadow-2xl bg-white  border-gray-200 rounded-lg"
                    >
                        <Modal.Title className="modal-title">{t('Add player')}</Modal.Title>

                        <Modal.Body>
                            <Form.Group
                                controlId="playerFirstName"
                                className="reg-form-group"
                            >
                                <Form.Label className="reg-form-label">
                                    {t('First name')}:
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    value={playerFirstName}
                                    onChange={handlePlayerFirstNameChange}
                                    className="reg-form-input"
                                    placeholder={t('Enter the first name')}
                                />
                            </Form.Group>

                            <Form.Group
                                controlId="playerSecondName"
                                className="reg-form-group"
                            >
                                <Form.Label className="reg-form-label">
                                    {t('Second name')}:
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    value={playerSecondName}
                                    onChange={handlePlayerSecondNameChange}
                                    className="reg-form-input"
                                    placeholder={t('Enter the second name')}
                                />
                            </Form.Group>

                            <Form.Group
                                controlId="playerLastName"
                                className="reg-form-group"
                            >
                                <Form.Label className="reg-form-label">
                                    {t('Last name')}:
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    value={playerLastName}
                                    onChange={handlePlayerLastNameChange}
                                    className="reg-form-input"
                                    placeholder={t('Enter the last name')}
                                />
                            </Form.Group>

                            <Form.Group
                                controlId="playerYearOfBirth"
                                className="reg-form-group"
                            >
                                <Form.Label className="reg-form-label">
                                    {t('Year of birth')}:
                                </Form.Label>
                                <YearSelector
                                    condition={teamType}
                                    onChange={handlePlayerYearOfBirthChange}
                                />
                            </Form.Group>

                            <Form.Group
                                controlId="playerNumber"
                                className="reg-form-group"
                            >
                                <Form.Label className="reg-form-label">
                                    {t('Player number')}:
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    min="1"
                                    max="99"
                                    value={playerNumber}
                                    onChange={handlePlayerNumberChange}
                                    className="reg-form-input"
                                    placeholder={t('Enter the player number')}
                                />
                            </Form.Group>
                        </Modal.Body>
                        {errorMessagePlayer && (
                            <p className="text-red-700 text-s">{errorMessagePlayer}</p>
                        )}
                        <Modal.Footer>
                            <Button color="orange" className="mr-8" onClick={handleModalSubmit}>
                                {t('Add')}
                            </Button>
                            <Button color="grey" onClick={handleModalClose}>
                                {t('Cancel')}
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <h3 className="reg-heading">{t('Officials')}</h3>
                    <p className="reg-details-text">
                        {t('officials.details.add')}
                    </p>

                    <Table>
                        <thead>
                        <Tr>
                            <Th>{t('First name')}</Th>
                            <Th>{t('Second name')}</Th>
                            <Th>{t('Last name')}</Th>
                            <Th>{t('Year of birth')}</Th>
                            <Th>{t('Actions')}</Th>
                        </Tr>
                        </thead>
                        <tbody>
                        {officials.map((p, index) => (
                            <Tr key={index} index={index}>
                                <Td>{p.firstName}</Td>
                                <Td>{p.secondName}</Td>
                                <Td>{p.lastName}</Td>
                                <Td>{p.yearOfBirth}</Td>
                                <Td className="">
                                    <button
                                        className="block font-medium text-sm justify-center align-middle text-red-700 "
                                        type="button"
                                        color="orange"
                                        onClick={() => handleDeleteOfficial(index)}
                                    >
                                        {t('Remove')}
                                    </button>
                                </Td>
                            </Tr>
                        ))}
                        </tbody>
                    </Table>

                    <Button type="button" onClick={handleAddOfficial} color="orange"
                            disabled={!isFormValid || officials.length >= MAX_OFFICIALS}>
                        {t('Add official')} ({officials.length}/{MAX_OFFICIALS})
                    </Button>

                    <Modal
                        show={showOfficialModal}
                        onHide={handleOfficialModalClose}
                        className="absolute md:left-0 w-fulltop-1/4 p-1 lg:top-1/4  lg:left-1/4 lg:w-1/3 lg:p-8 drop-shadow-2xl bg-white  border-gray-200 rounded-lg"
                    >
                        <Modal.Title className="modal-title">{t('Add official')}</Modal.Title>
                        <Modal.Body>
                            <Form.Group
                                controlId="officialFirstName"
                                className="reg-form-group"
                            >
                                <Form.Label className="reg-form-label">
                                    {t('First name')}:
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    value={officialFirstName}
                                    onChange={handleOfficialFirstNameChange}
                                    className="reg-form-input"
                                    placeholder={t('Enter the first name')}
                                />
                            </Form.Group>

                            <Form.Group
                                controlId="officialSecondName"
                                className="reg-form-group"
                            >
                                <Form.Label className="reg-form-label">
                                    {t('Second name')}:
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    value={officialSecondName}
                                    onChange={handleOfficialSecondNameChange}
                                    className="reg-form-input"
                                    placeholder={t('Enter the second name')}
                                />
                            </Form.Group>

                            <Form.Group
                                controlId="officialLastName"
                                className="reg-form-group"
                            >
                                <Form.Label className="reg-form-label">
                                    {t('Last name')}:
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    value={officialLastName}
                                    onChange={handleOfficialLastNameChange}
                                    className="reg-form-input"
                                    placeholder={t('Enter the last name')}
                                />
                            </Form.Group>

                            <Form.Group
                                controlId="officialYearOfBirth"
                                className="reg-form-group"
                            >
                                <Form.Label className="reg-form-label">
                                    {t('Year of birth')}:
                                </Form.Label>
                                <YearSelector onChange={handleOfficialYearOfBirthChange}/>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            {errorMessageOfficial && (
                                <p className="text-red-700 text-s">{errorMessageOfficial}</p>
                            )}
                            <Button color="orange" className="mr-8" onClick={handleOfficialModalSubmit}>
                                {t('Add')}
                            </Button>
                            <Button color="orange" onClick={handleOfficialModalClose}>
                                {t('Cancel')}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>


                <div className="">
                    {errorMessage && (
                        <p className="text-red-700 text-sm w-full">{errorMessage}</p>
                    )}

                    {successMessage && (
                        <p className="text-green-700 text-sm w-full">{successMessage}</p>
                    )}
                </div>
                <div className="col-start-5 col-end-6 mt-4 flex justify-end">
                    <Button
                        type="submit"
                        color="orange"
                        className="mr-8"
                        disabled={successMessage}
                    >
                        {t('Save team')}
                    </Button>
                    <Button
                        type="button"
                        onClick={handleCancel}
                        color="orange"
                        disabled={successMessage}
                    >
                        {t('Cancel')}
                    </Button>
                </div>
            </Form>
            ) : (
                // Message displayed when user is not logged in
                <Link to="/login">
                    <p className="text-blue-500 underline mt-8">{t("please.login")} </p>
                </Link>
               
            )}
        </Container>
    );
}

export default TeamRegister;
