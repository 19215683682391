import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from "./AuthContext";
import MatchCard from '../components/MatchCard';
import { Container, Row, Col, Form, Spinner, Alert } from 'react-bootstrap';

const MatchesPage = () => {
  const [matches, setMatches] = useState([]);
  const [filteredMatches, setFilteredMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [genderFilter, setGenderFilter] = useState("");

  const { isLoggedIn } = useContext(AuthContext);
  const { username } = useContext(AuthContext);
  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchMatches();
  }, []);

  const fetchMatches = async () => {
    try {
      const response = await axios.get(`/api/matches`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMatches(response.data);
      setFilteredMatches(response.data); // Initially show all matches
      setLoading(false);
    } catch (error) {
      setError('Failed to load matches');
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    filterMatches(query, categoryFilter, genderFilter);
  };

  const handleCategoryChange = (e) => {
    const category = e.target.value;
    setCategoryFilter(category);
    filterMatches(searchQuery, category, genderFilter);
  };

  const handleGenderChange = (e) => {
    const gender = e.target.value;
    setGenderFilter(gender);
    filterMatches(searchQuery, categoryFilter, gender);
  };

  const filterMatches = (query, category, gender) => {
    const filtered = matches.filter(
      (match) =>
        (match.teamA.toLowerCase().includes(query) ||
         match.teamB.toLowerCase().includes(query)) &&
        (!category || match.type === category) &&
        (!gender || match.gender === gender)
    );
    setFilteredMatches(filtered);
  };

  const groupByTypeAndGender = (matches) => {
    return matches.reduce((grouped, match) => {
      const type = match.type || 'Unknown Category';
      const gender = match.gender || 'Unknown Gender';
      const key = `${type} - ${gender}`;
      if (!grouped[key]) {
        grouped[key] = [];
      }
      grouped[key].push(match);
      return grouped;
    }, {});
  };

  if (loading) return <Spinner animation="border" role="status" className="text-center m-5"><span className="sr-only">Loading...</span></Spinner>;
  if (error) return <Alert variant="danger">{error}</Alert>;

  const groupedMatches = groupByTypeAndGender(filteredMatches);

  return (
    <Container className="mx-auto my-8 p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-2xl mb-4">Scheduled Matches</h1>

      {/* Search bar and filters */}
      <div className="flex gap-2 mb-4 z-10">
        {/* Name Filter */}
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearch}
          className="border border-gray-300 w-1/3 p-2 text-xs"
          placeholder="Search by team name"
        />

        {/* Category Dropdown */}
        <select
          value={categoryFilter}
          onChange={handleCategoryChange}
          className="border border-gray-300 w-1/3 p-2 text-xs"
        >
          <option value="">All Categories</option>
          <option value="NEW_TALENT">New Talents</option>
          <option value="CHILDREN">Children</option>
          <option value="SCHOOL">School</option>
          <option value="CADET">Cadet</option>
          <option value="YOUTH">Youth</option>
          <option value="ADULT">Adult</option>
          <option value="MASTER">Master</option>
        </select>

        {/* Gender Dropdown */}
        <select
          value={genderFilter}
          onChange={handleGenderChange}
          className="border border-gray-300 w-1/3 p-2 text-xs"
        >
          <option value="">All Genders</option>
          <option value="MAN">Man</option>
          <option value="WOMAN">Woman</option>
          <option value="MIXED">Mixed</option>
        </select>
      </div>

      {/* Render matches grouped by type and gender */}
      {Object.keys(groupedMatches).map((groupKey) => (
        <div key={groupKey} className="match-group mb-4">
          <h2 className="text-sm font-medium mt-4">{groupKey}</h2>
          <Row>
            {groupedMatches[groupKey].map((match) => (
              <Col md={6} lg={4} key={match.id} className="mb-4">
                <MatchCard match={match} />
              </Col>
            ))}
          </Row>
        </div>
      ))}
    </Container>
  );
};

export default MatchesPage;
