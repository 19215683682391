import React from 'react';

export const Table = ({ children }) => {
  return (
      <table className="w-full border-collapse shadow-lg z-10 rounded-lg overflow-hidden">
        {children}
      </table>
  );
};

export const Th = ({ children, onClick }) => {
  return (
      <th
          className="bg-table-header p-2 font-semibold text-sm text-left"
          onClick={onClick}>
        {children}
      </th>
  );
};

export const Td = ({ children, style, rowspan, className }) => {
  return (
      <td
          className={`p-2 text-left align-middle border-b border-gray-200 text-sm ${className}`}
          style={style} rowspan={rowspan}>
        {children}
      </td>
  );
};
export const Tr = ({ children, index, onClick }) => {
    const rowColor = index % 2 === 0 ? 'bg-white' : 'bg-gray-50';

    return (
        <tr className={`hover:bg-orange-100 cursor-pointer ${rowColor} text-sm`} onClick={onClick}>
            {children}
        </tr>
    );
};