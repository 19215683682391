import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Container, Form} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import CountrySelector from "../components/CountrySelector";
import Button from "../components/Button";
import {AuthContext} from "./AuthContext";

function RefereeRegister() {

    const MAX_FILE_SIZE_MB = 4;
    const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

    const navigate = useNavigate();
    const {t} = useTranslation();

    const [fullName, setFullName] = useState("");
    const [birthDate, setBirthDate] = useState(null);
    const [country, setCountry] = useState(null);
    const [federationName, setFederationName] = useState("");
    const [reasoning, setReasoning] = useState("");
    const [cv, setCv] = useState(null);
    const [IDPic, setIDPic] = useState(null);
    const [registered, isRegistered] = useState(false);




    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const isFormValid = fullName && cv && IDPic && country && reasoning ; // Check if all required fields are filled

    const [isEnabledApply, setEnabledApply] = useState(true);




    const {username, setUsername} = useContext(AuthContext);
    const { isLoggedIn, setLoggedOut } = useContext(AuthContext);


    const handleFullNameChange = (event) => {
        setFullName(event.target.value);
    };

    const handleBirthDateChange = (event) => {
        setBirthDate(event.target.value);
    };

    const handleCountryChange = (value) => {
        setCountry(value);
    };

    const handleFederationNameChange = (event) => {
        setFederationName(event.target.value);
    };

    const heandleReasoningChange = (event) => {
        setReasoning(event.target.value);
    };

    const handleCVChange = (event) => {
        const file = event.target.files[0];
        if (file.size > MAX_FILE_SIZE_BYTES) {
            alert(t("alert.file.limit"));
            return;
        }
        setCv(file);
    };

    const handleIDPicChange = (event) => {
        const file = event.target.files[0];
        if (file.size > MAX_FILE_SIZE_BYTES) {
            alert(t("alert.file.limit"));
            return;
        }
        setIDPic(file);
    };

    useEffect(() => {

        if(!isLoggedIn) {
            return;
        }

        const url = new URL(`/api/referees/${username}`, window.location.origin);
        const token = localStorage.getItem("token");


        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`, // Include token in the Authorization header if needed
            },
            credentials: "include", // Include cookies in the request
        })
            .then(response  => {
                if(response.status === 200){
                    isRegistered(true);
                }})
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []); // Empty array makes this

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!isFormValid) {
            setErrorMessage(t("form.incomplete"));
            return;
        }

        setErrorMessage("");
        setEnabledApply(false);

        const token = localStorage.getItem("token");


        const formData = new FormData();
        formData.append('userName', username);
        formData.append('name', fullName);
        formData.append('birthDate', birthDate);
        formData.append('nationality', country);
        formData.append('reasoning', reasoning);

        // Assuming you've the picture and cv files in the state
        formData.append('cv', cv);
        formData.append('picture', IDPic);

        for (let key of formData.entries()) {
            console.log(key[0] + ', ' + key[1]);
        }

        fetch('/api/referees', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`, // Include token in the Authorization header
            },
            body: formData,
            credentials: "include",
        })
            .then(response => {
                if(response.status === 201){
                    // If status code is 201, set the success message
                    setSuccessMessage(t("Referee registration successful"));

                    setTimeout(() => {
                        navigate("/refereereg");
                    }, 5000);
                }

            })
            .catch((error) => {
                setErrorMessage(error);
                setEnabledApply(true);
            });
    }



    return (
        <Container className="mx-auto my-8 p-6 bg-white rounded-lg shadow-md">
            <h1 className="text-2xl">{t('Referee application form')}</h1>
            <p className="text-gray-800 mt-8">{t("referee.intro1")}</p>
            <p className="text-gray-800 mt-4">{t("referee.intro2")}</p>

            {isLoggedIn ? (
                registered ? (
                    <p className="text-gray-800 mt-8" >{t("referee.processing")}</p>
                ): (
            <>
            <p className="text-gray-600 text-justify my-8">
                {t('referee.register')}
            </p>


                    <Form
                        onSubmit={handleSubmit}>

                        <Form.Group
                            controlId="fullName"
                            className="reg-form-group"
                        >
                            <Form.Label className="referee-form-label">{t('Full name')}*:</Form.Label>
                            <Form.Control
                                type="text"
                                value={fullName}
                                onChange={handleFullNameChange}
                                placeholder={t('full.name.details')}
                                className="referee-form-input"
                            />
                        </Form.Group>

                        <Form.Group
                            controlId="birthDate"
                            className="flex gap-2 my-4 text-sm justify-start"
                        >
                            <Form.Label className="referee-form-label">
                                {t('Date of birth')}*:
                            </Form.Label>
                            <Form.Control className="referee-form-input"
                                          type="date"
                                          value={birthDate}
                                          onChange={handleBirthDateChange}
                                          placeholder={t("Date of birth")}/>
                        </Form.Group>

                        <Form.Group
                            controlId="country"
                            className="flex gap-2 my-4 text-sm justify-start"
                        >
                            <Form.Label
                                className="referee-form-label">{t('Country of origin')}*:</Form.Label>
                            <CountrySelector
                                onChange={handleCountryChange}
                                value={country}
                                className="w-1/2"
                                placeholder={t('Select your country')}
                            />
                        </Form.Group>

                        <Form.Group
                            controlId="federationName"
                            className="reg-form-group"
                        >
                            <Form.Label
                                className="referee-form-label">{t('Federation')}*:</Form.Label>
                            <Form.Control
                                type="text"
                                value={federationName}
                                onChange={handleFederationNameChange}
                                placeholder={t('federation.details')}
                                className="referee-form-input"
                            />
                        </Form.Group>

                        <Form.Group
                            controlId="reasoning"
                            className="reg-form-group"
                        >
                            <Form.Label
                                className="referee-form-label">{t('referee.reasoning')}*:</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                maxLength="300"
                                value={reasoning}
                                onChange={heandleReasoningChange}
                                placeholder={t('referee.reasoning.details')}
                                className=" referee-form-input-ho-height"
                            />
                        </Form.Group>

                        <Form.Group
                            controlId="uploadCV"
                            className="reg-form-group"
                        >
                            <Form.Label className="referee-form-label">{t('referee.cv')}:</Form.Label>
                            <Form.Control
                                type="file"
                                accept=".pdf"
                                onChange={handleCVChange}
                                className="referee-form-input"
                            />
                        </Form.Group>

                        <Form.Group
                            controlId="uploadIDPic"
                            className="reg-form-group"
                        >
                            <Form.Label
                                className="referee-form-label">{t('referee.id')}:</Form.Label>
                            <Form.Control
                                type="file"
                                accept=".jpg, .jpeg"
                                onChange={handleIDPicChange}
                                className="referee-form-input"
                            />
                        </Form.Group>


                        <div className="">
                            {errorMessage && (
                                <p className="text-red-700 text-sm w-full">{errorMessage}</p>
                            )}

                            {successMessage && (
                                <p className="text-green-700 text-sm w-full">{successMessage}</p>
                            )}
                        </div>

                        <div className="col-start-5 col-end-6 mt-4 flex">
                            <Button
                                type="submit"
                                color="orange"
                                className="mr-8"
                                disabled={!isEnabledApply}
                            >
                                {t('Apply')}
                            </Button>
                        </div>

                    </Form>

            <h2 className="text-lg font-medium mt-8 mb-4">{t("Privacy policy")}</h2>
            <p className="text-gray-600 mt-2 text-justify">{t("privacy.info.referee")}</p>

            </>
                )
            ) : (
                // Message displayed when user is not logged in
                <Link to="/login">
                <p className="text-blue-500 underline mt-8">{t("please.login")}</p>
                </Link>
            )}
        </Container>
    );
}

export default RefereeRegister;