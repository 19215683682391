import React from 'react';
import PropTypes from 'prop-types';

const MatchCard = ({ match }) => {
  const date = new Date(match.timestamp);
  const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  return (
    <div className="match-card border border-gray-300 py-2 px-4 my-2 rounded-lg flex items-center bg-white relative overflow-hidden">
      {/* Background blur effect */}
      <div className="absolute inset-0 bg-gray-100 opacity-50 rounded-lg blur-sm" style={{ filter: 'blur(8px)' }} />
      
      {/* First Column: Timestamp */}
      <div className="match-time font-semibold text-sm text-gray-600" style={{ width: '80px', textAlign: 'left', position: 'relative', zIndex: 1 }}>
        {formattedTime}
      </div>

      {/* Second Column: Team A */}
      <div
        className="team-a text-sm font-semibold"
        style={{
          backgroundColor: `${match.colorA}99`, // Using RGBA or a hex with alpha for less vivid color
          borderRadius: '8px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          flex: '1 1 0', // Allows team A to grow and occupy space
          textAlign: 'right', // Align text to the right
          padding: '4px 8px', // Added padding for better aesthetics
          position: 'relative', // Positioning to ensure it sits above the blur
          zIndex: 1,
        }}
      >
        {match.teamA}
      </div>

      {/* Third Column: Score */}
      <div className="score text-lg font-semibold mx-4" style={{ width: '50px', textAlign: 'center', position: 'relative', zIndex: 1 }}>
        {match.scoreA} - {match.scoreB}
      </div>

      {/* Fourth Column: Team B */}
      <div
        className="team-b text-sm font-semibold"
        style={{
          backgroundColor: `${match.colorB}99`, // Using RGBA or a hex with alpha for less vivid color
          borderRadius: '8px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          flex: '1 1 0', // Allows team B to grow and occupy space
          textAlign: 'left', // Align text to the left
          padding: '4px 8px', // Added padding for better aesthetics
          position: 'relative', // Positioning to ensure it sits above the blur
          zIndex: 1,
        }}
      >
        {match.teamB}
      </div>

      {/* Fifth Column: Field */}
      <div className="match-type text-sm text-gray-500" style={{ width: '80px', textAlign: 'right', position: 'relative', zIndex: 1 }}>
        {match.field}
      </div>
    </div>
  );
};

MatchCard.propTypes = {
  match: PropTypes.shape({
    timestamp: PropTypes.string.isRequired,
    teamA: PropTypes.string.isRequired,
    teamB: PropTypes.string.isRequired,
    scoreA: PropTypes.number.isRequired,
    scoreB: PropTypes.number.isRequired,
    field: PropTypes.string.isRequired,
    colorA: PropTypes.string.isRequired,
    colorB: PropTypes.string.isRequired,
  }).isRequired,
};

export default MatchCard;
