import React,  { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import App from './App';
import LoginPage from './LoginPage';
import TeamsPage from './TeamsPage';
import PasswordReset from './PasswordReset';
import { AuthProvider } from './AuthContext';
import Header from './Header';
import RegistrationPage from './Signup';
import TeamRegister from './TeamRegister';
import TournamentPage from './TournamentPage';
import ContactPage from './ContactPage';
import UsersPage from './UsersPage';
import i18n from '../components/i18n';
import LocaleContext  from '../LocaleContext';
import { Suspense } from 'react';
import Loading from '../components/Loading';
import RefereeRegisterPage from "./RefereeRegisterPage";
import ExplorePage from "./ExplorePage";
import MyTeamsPage from "./MyTeamsPage";
import MatchesPage from "./MatchesPage";

function AppRouter() {

  const [locale, setLocale] = useState(i18n.language);
  i18n.on('languageChanged', (lng) => setLocale(i18n.language));


  return (
    <LocaleContext.Provider value={{locale, setLocale}}>
    <Suspense fallback={<Loading />}>
      <AuthProvider>
        <Header>
          <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" rel="stylesheet"/>
        </Header>
        <Routes>
          <Route path="/" element={<App/>}/>
          <Route path="/login" element={<LoginPage/>}/>
          <Route path="/teams" element={<TeamsPage/>}/>
          <Route path="/users" element={<UsersPage/>}/>
          <Route path="/myteams" element={<MyTeamsPage/>}/>
          <Route path="/signup" element={<RegistrationPage/>}/>
          <Route path="/matches" element={<MatchesPage/>}/>
          <Route path="/teamreg" element={<TeamRegister/>}/>
          <Route path="/tournament" element={<TournamentPage/>}/>
          <Route path="/contact" element={<ContactPage/>}/>
          <Route path="/forgotPassword" element={<PasswordReset/>}/>
          <Route path="/refereereg" element={<RefereeRegisterPage/>}/>
          <Route path="/explore" element={<ExplorePage/>}/>
      </Routes>
    </AuthProvider>
    </Suspense>
</LocaleContext.Provider>
)
  ;
}

export default AppRouter;