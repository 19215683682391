import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import { useTranslation } from "react-i18next";

function RegistrationPage() {
  const { t } = useTranslation();

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const navigate = useNavigate();

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handlePasswordAgainChange = (event) => {
    setPasswordAgain(event.target.value);
  };

  const validateInputFields = (password) => {
    if (password.length < 8) {
      setErrorMessage(t("short.password"));
      return false;
    }

    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/;

    if (!passwordRegex.test(password)) {
      setErrorMessage(t("password.requirements"));
      return false;
    }

    if(password !== passwordAgain){
      setErrorMessage(t("passwords.dont.match"));
      return false;
    }

    setUsername(username.trim());

    return true;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if(!validateInputFields(password)) {
      return;
    }
    
    setSuccessMessage(t("registration.started"));
    setErrorMessage("");

    try {
      const response = await fetch("/api/auth/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          email,
          password,
          role: ["coach"],
        }),
      });

      if (response.ok) {
        console.log("Registration successful");
        setSuccessMessage(t("registration.success"));
        setErrorMessage(""); // clear the error message

       setTimeout(() => {
        navigate("/");
      }, 5000);
      } else {
        // Call response.json() to get the response body
        const errorResponse = await response.json();
        setErrorMessage(errorResponse.message); // assuming errorResponse has a message property
        setSuccessMessage("");
        console.error("Registration failed:", errorResponse);
      }
    } catch (error) {
      console.error("Registration failed", error);
    }
  };

  return (
    <div className="container  mx-auto my-8 p-4 bg-white rounded-md shadow-lg text-justify max-w-xs">
      <div className="flex flex-col  gap-5 items-center">
        <h1 className="text-2xl ">{t('home.createAccount')}</h1>
          <Form onSubmit={handleSubmit} className="">
            <Form.Group
              controlId="formUsername"
              className="flex flex-col mx-auto gap-2 mb-4"
            >
              <Form.Label className="signup-form-label">{t('Username')}:</Form.Label>
              <Form.Control
                type="text"
                value={username}
                onChange={handleUsernameChange}
                className="signup-form-input"
              />
            </Form.Group>

            <Form.Group
              controlId="formEmail"
              className="login-form-group "
            >
              <Form.Label className="signup-form-label">{t('Email')}:</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={handleEmailChange}
                className="signup-form-input"
              />
            </Form.Group>

            <Form.Group
              controlId="formPassword"
              className="login-form-group"
            >
              <Form.Label className="signup-form-label">{t('Password')}:</Form.Label>
              <Form.Control
                type="password"
                value={password}
                onChange={handlePasswordChange}
                className="signup-form-input"
              />
            </Form.Group>

            <Form.Group
              controlId="formPasswordAgain"
              className="login-form-group"
            >
              <Form.Label className="signup-form-label">
              {t('Confirm password')}:
              </Form.Label>
              <Form.Control
                type="password"
                value={passwordAgain}
                onChange={handlePasswordAgainChange}
                className="signup-form-input" 
              />
            </Form.Group>

            <p className="text-sm mt-8">
              {t('By registering, you accept our')}{" "}
              <a href={require("../resources/documents/PrivacyPolicy.pdf")} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                 {t('Privacy Policy')}
              </a>
               .
            </p>
            {errorMessage && (
              <p className="error-message">
                {errorMessage}
              </p>
            )}

            {successMessage && (
              <p className="success-message">
                {successMessage}
              </p>
            )}


            <Button
              type="submit"
              color="orange"
              className="bg-orange-300 text-slate-800 font-semibold rounded my-4"
              disabled={!!successMessage}
            >
              {t('Register')}
            </Button>
          </Form>
        </div>
      </div>
  );
}

export default RegistrationPage;
