import React, { useContext, useState } from "react";
import { AuthContext } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import Button from "../components/Button";
import { useTranslation } from "react-i18next";


function PasswordReset() {
    const { t } = useTranslation(); 
    const navigate = useNavigate();


    const [email, setEmail] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("");
    const [errorMessage, setErrorMessage] = useState("");


    const handlePasswordChange = (event) => {
      setPassword(event.target.value);
    };
  
    const handlePasswordAgainChange = (event) => {
      setPasswordAgain(event.target.value);
    };
  
    const validateInputFields = (password) => {
      if (password.length < 8) {
        setErrorMessage(t("short.password"));
        return false;
      }
  
      const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/;
  
      if (!passwordRegex.test(password)) {
        setErrorMessage(t("password.requirements"));
        return false;
      }
  
      if(password !== passwordAgain){
        setErrorMessage(t("passwords.dont.match"));
        return false;
      }
    
      return true;
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSubmit = async (event) => {
      event.preventDefault();

      if(!validateInputFields(password)) {
        return;
      }
      
      setSuccessMessage(t("reset.started"));
      setErrorMessage("");
  
    
        try {
          const response = await fetch("/api/auth/reset", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email,
              password
            }),
          });
  
    
          if (response.ok) {
    
            setSuccessMessage(t("reset.success"));
            setTimeout(() => {
              navigate("/");
            }, 5000);
          }  else {
            console.log("Reset failed");
          }
        } catch (error) {
          console.error("Reset failed", error);
        }
      };

    return (
        <div className="container mx-auto my-8 p-4 bg-white rounded-md shadow-lg max-w-xs">
          <div className="flex flex-col items-center gap-5">
            <h1 className="text-2xl ">{t('Password reset')}</h1>
            <Form onSubmit={handleSubmit} >
              <Form.Group className="login-form-group">
                <Form.Label className="login-form-label">{t('email')}:</Form.Label>
                <Form.Control
                  type="text"
                  onChange={handleEmailChange}
                  className="login-form-input"
                />
              </Form.Group>

              <Form.Group
              controlId="formPassword"
              className="login-form-group"
            >
              <Form.Label className="signup-form-label">{t('Password')}:</Form.Label>
              <Form.Control
                type="password"
                value={password}
                onChange={handlePasswordChange}
                className="signup-form-input"
              />
            </Form.Group>

            <Form.Group
              controlId="formPasswordAgain"
              className="login-form-group"
            >
              <Form.Label className="signup-form-label">
              {t('Confirm password')}:
              </Form.Label>
              <Form.Control
                type="password"
                value={passwordAgain}
                onChange={handlePasswordAgainChange}
                className="signup-form-input" 
              />
            </Form.Group>

            {errorMessage && (
              <p className="error-message">
                {errorMessage}
              </p>
            )}

            {successMessage && (
              <p className="success-message">
                {successMessage}
              </p>
            )}
    
              <div className="flow-root">
                <Button className="float-left" color="orange" type="submit">{t('Reset')}</Button>
              </div>
            </Form>
          </div>
        </div>
      );
    }
    
    export default PasswordReset;
    