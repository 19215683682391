import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "./AuthContext";
import { Nav, Navbar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "../styles/Header.css";
import i18n from "../components/i18n";
import LocaleContext from "../LocaleContext";
import { useTranslation } from "react-i18next";
import spanishFlag from '../resources/flags/Flag_of_Spain.svg';
import englishFlag from '../resources/flags/Flag_of_United_Kingdom.svg';

const Header = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { isLoggedIn, setLoggedOut } = useContext(AuthContext);
  const [selectedLink, setSelectedLink] = useState(null);
  const { username, setUsername, userRoles } = useContext(AuthContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const { locale, setLocale } = useContext(LocaleContext);

  const [selectedOption, setSelectedOption] = useState(locale);
  function changeLocale(l) {
    if (locale !== l) {
      i18n.changeLanguage(l);
    }
  }

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    changeLocale(event.target.value);
  };

  const handleClick = (link) => {
    setSelectedLink(link);
    setMenuOpen(false);
  };

  const handleMenuToggle = () => {
    setMenuOpen((prevMenuOpen) => !prevMenuOpen);
  };
  // Function to handle logout
  const handleLogout = () => {
    setLoggedOut();
    // Perform any other necessary cleanup or redirection
  };

  // Read username from storage on component mount
  useEffect(() => {
    const storedSelectedLink = localStorage.getItem("selectedLink");
    if (storedSelectedLink) {
      setSelectedLink(storedSelectedLink);
    }

    const storedUsername = localStorage.getItem("username");
    setUsername(storedUsername);
  }, []); // Empty dependency array to run the effect only once on mount

  useEffect(() => {
    setSelectedLink(location.pathname);
  }, [location]);

  return (
    <Navbar className="top-0 bg-header-background p-0.5 drop-shadow-lg lg:flex lg:flex relative w-full lg:justify-center">
      <a href="/" className="font-bold text-lg px-4  hover:text-black w-44 lg:left-0 ">
        Handball X
      </a>

      <button
        className="lg:hidden bg-header-background hover:stroke-white"
        onClick={handleMenuToggle}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          stroke="black"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={3}
            d="M4 6h20M4 12h20M4 18h20"
          />
        </svg>
      </button>

      <Nav
        className={`container lg:flex ${menuOpen ? "block" : "hidden"}  `}
      >
        <ul className="flex flex-col text-xs gap-3 uppercase font-medium mx-5 my-5 lg:mx-auto lg:my-auto lg:items-center lg:flex lg:flex-row">
          <li className="">
            <Link
                to="/"
                onClick={() => handleClick("home")}
                className={selectedLink === "/" ? "selected" : ""}
            >
              {t("Home")}
            </Link>
          </li>
          
          {userRoles.includes('ROLE_ADMIN') && (
           <>
              <li>
                <Link
                    to="/teams"
                    onClick={() => handleClick("teams")}
                    className={selectedLink === "/teams" ? "selected" : ""}
                >
                  {t("Teams")}
                </Link>
              </li>
              <li>
                <Link
                    to="/matches"
                    onClick={() => handleClick("matches")}
                    className={selectedLink === "/matches" ? "selected" : ""}
                >
                  {t("Matches")}
                </Link>
              </li>
              <li>
              <Link
                  to="/users"
                  onClick={() => handleClick("users")}
                  className={selectedLink === "/users" ? "selected" : ""}
              >
                {t("Users")}
              </Link>
            </li>
          </> 
          )}
          
          <li>
            <Link
                onClick={() => alert(t("team.registration.closed"))}
                className="text-gray-600"
            >
              {t("Team Registration")}
            </Link>
          </li>

          <li>
            <Link
                to="/myteams"
                onClick={() => handleClick("myteams")}
                className={selectedLink === "/myteams" ? "selected" : ""}
            >
              {t("My Teams")}
            </Link>
          </li>

          <li>
            <Link
                to="/tournament"
                onClick={() => handleClick("tournament")}
                className={selectedLink === "/tournament" ? "selected" : ""}
            >
              {t("Tournament")}
            </Link>
          </li>
          <li>
            <Link
                to="/refereereg"
                onClick={() => handleClick("refereereg")}
                className={selectedLink === "/refereereg" ? "selected" : ""}
            >
              {t("Referees")}
            </Link>
          </li>
          <li>
            <Link
                to="/explore"
                onClick={() => handleClick("explore")}
                className={selectedLink === "/explore" ? "selected" : ""}
            >
              {t("Explore")}
            </Link>
          </li>
          <li>
            <Link
                to="/contact"
                onClick={() => handleClick("/contact")}
                className={selectedLink === "contact" ? "selected" : ""}
            >
              {t("Contact")}
            </Link>
          </li>
        </ul>
        <div className="flex flex-row lg:justify-end">
          {isLoggedIn && (
              <div
                  className="gap-2 font-medium mt-5 mx-5 text-xs flex flex-col lg:flex lg:pt-0.5 lg:gap-4 lg:my-auto lg:flex-row lg:mx-0">
                <a className="" href="#">
                  {t("User")}: {username}
                </a>

                <a href="#" onClick={handleLogout}>
                  {t("Logout")}
                </a>
                <div className="gap-2 font-medium flex flex-row text-xs lg:flex lg:gap-0 lg:my-auto lg:flex-row lg:mx-0">

                <select
                    value={selectedOption}
                    onChange={handleOptionChange}
                    className="bg-header-background  lg:mx-4 -mx-1"
                >
                  <option value="en">EN</option>
                  <option value="es">ES</option>
                </select>
                <img className=""
                     src={locale === 'en' ? englishFlag : spanishFlag}
                     alt={locale === 'en' ? "English Flag" : "Spanish Flag"}
                     width={30}
                />
                </div>
              </div>
          )}
          {!isLoggedIn && (
              <div
                  className="gap-2 font-medium mt-5 mx-5 flex flex-col text-xs lg:flex lg:gap-4 lg:my-auto lg:flex-row lg:mx-0">
                <Link
                    className=""
                    to="/login"
                    onClick={() => handleClick("/login")}
                >
                  {t("Login")}
                </Link>

                <Link to="/signup" onClick={() => handleClick("/signup")}>
                  {t("Signup")}
                </Link>

                <div className="gap-2 font-medium flex flex-row text-xs lg:flex lg:gap-0 lg:my-auto lg:flex-row lg:mx-0">
                <select
                    value={selectedOption}
                    onChange={handleOptionChange}
                    className="bg-header-background  lg:mx-4 -mx-1"
                >
                  <option value="en">EN</option>
                  <option value="es">ES</option>
                </select>
                <img className="mr-4"
                    src={locale === 'en' ? englishFlag : spanishFlag }
                    alt={locale === 'en' ? "English Flag" : "Spanish Flag"}
                    width={30}
                />
                </div>
              </div>
          )}
        </div>
      </Nav>
    </Navbar>
  );
};

export default Header;
