import React from 'react';
import { useTranslation } from "react-i18next";


const YearSelector = ({ condition, onChange }) => {
  const { t } = useTranslation();

  const handleYearChange = (event) => {
    onChange(event.target.value);
  };

  const getYears = () => {
    let years = [];
    let startYear = 2005;
    let endYear = 1950;
    
    if (condition === 'NEW_TALENT') {
      startYear = 2018;
      endYear = 2016;
    } else if (condition === 'SCHOOL') {
      startYear = 2015;
      endYear = 2013;
    } else if (condition === 'CHILDREN') {
      startYear = 2013;
      endYear = 2011;
    } else if (condition === 'CADET') {
      startYear = 2010;
      endYear = 2009;
    } else if (condition === 'YOUTH') {
      startYear = 2008;
      endYear = 2007;
    } else if (condition === 'ADULT') {
      startYear = 2006;
    } else if (condition === 'MASTER') {
      startYear = 1989;
    }

    for (let year = startYear; year >= endYear; year--) {
      years.push(year);
    }

    return years;
  };

  const years = getYears();

  return (
    <select className=" w-full block h-6 text-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 border border-gray-300 rounded-md shadow-sm" onChange={handleYearChange}>
      <option value="">{t('Select the year of birth')}</option>
      {years.map((year) => (
        <option key={year} value={year}>
          {year}
        </option>
      ))}
    </select>
  );
};

export default YearSelector;