import React from 'react';
import { FaEnvelope, FaFacebook, FaInstagram } from 'react-icons/fa';
import { useTranslation } from "react-i18next";



function ContactPage() {
  const { t } = useTranslation();


  return (
    <div className="container mx-auto my-8 p-8 bg-white rounded-md shadow-lg text-sm">
  <h1 className="text-2xl mb-8">{t('Contact Us')}</h1>
  <p className="flex items-center mb-4">
    <FaEnvelope className="mr-2" /> Email: <span className="text-blue-500"> info@xantolohandball.com</span>
  </p>
  <p className="flex items-center mb-4">
    <FaFacebook className="mr-2" /> Facebook: {" "}
    <a href="https://www.facebook.com/festivalxantolodehandball" className="text-blue-500 underline"> Xantolo Handball</a>
  </p>
  <p className="flex items-center mb-4">
    <FaInstagram className="mr-2" /> Instagram: {" "}
    <a href="https://www.instagram.com/festivalxantolodehandball" className="text-blue-500 underline"> @festivalxantolodehandball</a>
  </p>
</div>
  );    
}

export default ContactPage;
