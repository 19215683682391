import {Table, Th, Td, Tr} from "../components/Table";
import {useTranslation} from "react-i18next";

function TournamentPage() {
    const {t} = useTranslation();

    return (
        <div className="container flex-row mx-auto my-8 p-8 bg-white rounded-md shadow-lg text-sm text-justify">
            <h1 className="text-2xl mb-4">{t("Tournament information")}</h1>

            <h2 className="text-lg font-medium">{t("Schedule")}</h2>

            <div className="flex flex-wrap -mx-4 p-4 lg:w-2/3">

                <div className="w-full md:w-1/2 p-4 flex">
                    <div
                        className="flex flex-col p-6 rounded-lg border border-gray-200 bg-gradient-to-b from-white to-gray-50 shadow w-full">
                        <h3 className="mb-4 text-lg font-semibold text-gray-700">{t("Thursday, October 31:")}</h3>
                        <ul className="list-disc list-inside text-gray-600 flex-grow">
                            <li>{t("Arrival of the teams")}</li>
                            <li>{t("Tourism in Huasteca Potosina")}</li>
                            <li>{t("Xantolo commemorative shows and activities")}</li>
                        </ul>
                    </div>
                </div>

                <div className="w-full md:w-1/2 p-4 flex">
                    <div
                        className="flex flex-col p-6 rounded-lg border border-gray-200 bg-gradient-to-b from-white to-gray-50 shadow w-full">
                        <h3 className="mb-4 text-lg font-semibold text-gray-700">{t("Friday, November 1:")}</h3>
                        <ul className="list-disc list-inside text-gray-600">
                            <li>{t("1st match day")}</li>
                            <li>{t("Tourism in Huasteca Potosina")}</li>
                            <li>{t("Xantolo commemorative shows and activities")}</li>
                            <li>{t("Opening ceremony")}</li>
                        </ul>
                    </div>
                </div>

                <div className="w-full md:w-1/2 p-4 flex">
                    <div
                        className="flex flex-col p-6 rounded-lg border border-gray-200 bg-gradient-to-b from-white to-gray-50 shadow w-full">
                        <h3 className="mb-4 text-lg font-semibold text-gray-700">{t("Saturday, November 2:")}</h3>
                        <ul className="list-disc list-inside text-gray-600">
                            <li>{t("2nd match day")}</li>
                            <li>{t("Tourism in the Huasteca Potosina")}</li>
                            <li>{t("Xantolo commemorative shows and activities")}</li>
                        </ul>
                    </div>
                </div>

                <div className="w-full md:w-1/2 p-4 flex">
                    <div
                        className="flex flex-col p-6 rounded-lg border border-gray-200 bg-gradient-to-b from-white to-gray-50 shadow w-full">
                        <h3 className="mb-4 text-lg font-semibold text-gray-700">{t("Sunday, November 3:")}</h3>
                        <ul className="list-disc list-inside text-gray-600">
                            <li>{t("3rd match day")}</li>
                            <li>{t("Awards and closing ceremony")}</li>
                            <li>{t("Departure of the teams")}</li>
                        </ul>
                    </div>
                </div>

            </div>


            <h2 className="text-xl font-semibold text-gray-800 mb-2">{t("Location")}</h2>
            <p className="text-gray-600 mb-2">{t("location.address")}</p>
            <a
                className="text-blue-600 underline"
                href="https://www.google.com/maps/place/Manuel+Gomez+Morin/@21.972597,-98.9947448,17.44z/data=!4m6!3m5!1s0x85d66d4160a845b3:0x3f138be187d1133!8m2!3d21.9718894!4d-98.994812!16s%2Fg%2F11bbrhvcm1?entry=ttu"
                target="_blank"
                rel="noreferrer">
                {t("location.maps")}
            </a>

            <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-2">{t("Courts")}</h2>
            <p className="text-gray-600">{t("courts.info")}</p>

            <h2 className="text-lg font-medium mt-6 mb-2">{t("Participation rules")}</h2>
            <div className="w-full lg:w-1/2">
                <Table>
                    <Tr index={1}>
                        <Th>{t("Category")}</Th>
                        <Th>{t("Birth Year")}</Th>
                        <Th>{t("Gender")}</Th>
                    </Tr>
                    <Tr index={2}>
                        <Td>{t("New talents")}</Td>
                        <Td>2016-2018</Td>
                        <Td>{t("mixed")}</Td>
                    </Tr>
                    <Tr index={3}>
                        <Td>{t("School")}</Td>
                        <Td>2013-2015</Td>
                        <Td>{t("Women and Men")}</Td>
                    </Tr>
                    <Tr index={4}>
                        <Td>{t("Children")}</Td>
                        <Td>2011-2013</Td>
                        <Td>{t("Women and Men")}</Td>
                    </Tr>
                    <Tr index={5}>
                        <Td>{t("Cadet")}</Td>
                        <Td>2009-2010</Td>
                        <Td>{t("Women and Men")}</Td>
                    </Tr>
                    <Tr index={6}>
                        <Td>{t("Youth")}</Td>
                        <Td>2007-2008</Td>
                        <Td>{t("Women and Men")}</Td>
                    </Tr>
                    <Tr index={7}>
                        <Td>{t("category.adults")}</Td>
                        <Td>{t("2006 and prior")}</Td>
                        <Td>{t("Women and Men")}</Td>
                    </Tr>
                    <Tr index={8}>
                        <Td>{t("Master")}</Td>
                        <Td>{t("1989 and prior")}</Td>
                        <Td>{t("Women and Men")}</Td>
                    </Tr>
                </Table>
            </div>
            <p className="mt-4 text-gray-600">{t("participation_rules.info")}</p>


            <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-2">
                {t("Competition and/or participation system")}
            </h2>
            <p className="text-gray-600 mb-2">
                {t("competition_and_or_participation_system.info")}
            </p>

            <h3 className="text-md font-medium text-gray-700">{t("New talents")}:</h3>
            <ul className="list-disc pl-8 text-gray-600">
                <li>{t("new_talents.info1")}</li>
                <li>{t("new_talents.info2")}</li>
            </ul>
            <h3 className="text-md font-medium text-gray-700 mt-2">{t("School")}:</h3>
            <ul className="list-disc pl-8 text-gray-600">
                <li>{t("school.info1")}</li>
                <li>{t("school.info2")}</li>
            </ul>
            <h3 className="text-md font-medium text-gray-700 mt-2">{t("Other categories")}:</h3>
            <ul className="list-disc pl-8 text-gray-600 mb-4">
                <li>{t("other.info1")}</li>
            </ul>

            <ul className="list-disc pl-4 mt-4 text-gray-600">
                <li className="text-gray-600 mt-2">{t("rule1")}</li>
                <li className="text-gray-600 mt-2">{t("rule2")}</li>
                <li className="text-gray-600 mt-2">{t("rule3")}</li>
                <li className="text-gray-600 mt-2">{t("rule4")}</li>
                <li className="text-gray-800 mt-2">{t("rule5")}

                    <div className="p-4 bg-gray-50 my-4 border rounded-md lg:w-1/3 text-center">
                        <p className="">{t("payment.temp")}</p>
                    </div>
                </li>
                <li className="text-gray-600 mt-2">{t("rule6")}</li>
                <li className="text-gray-600 mt-2">{t("rule7")}</li>
                <li className="text-gray-600 mt-2">{t("rule8")}</li>
                <li className="text-gray-600 mt-2">{t("rule9")}</li>
                <li className="text-gray-600 mt-2">{t("rule10")}</li>
            </ul>


            <h2 className="text-lg font-medium mt-6 mb-2">{t("Ball")}</h2>
            <div className="w-full lg:w-2/3">
                <Table>
                    <Tr index={1}>
                        <Th>{t("Category")}</Th>
                        <Th>{t("Birth Year")}</Th>
                        <Th>{t("Gender")}</Th>
                        <Th>{t("Ball")} #</Th>
                        <Th>{t("Gender")}</Th>
                        <Th>{t("Ball")} #</Th>
                    </Tr>
                    <Tr index={2}>
                        <Td>{t("New talents")}</Td>
                        <Td>2016-2018</Td>
                        <Td>{t("mixed")}</Td>
                        <Td>0</Td>
                        <Td></Td>
                        <Td></Td>
                    </Tr>
                    <Tr index={3}>
                        <Td>{t("School")}</Td>
                        <Td>2013-2015</Td>
                        <Td>{t("woman")}</Td>
                        <Td>1</Td>
                        <Td>{t("man")}</Td>
                        <Td>1</Td>
                    </Tr>
                    <Tr index={4}>
                        <Td>{t("Children")}</Td>
                        <Td>2011-2013</Td>
                        <Td>{t("woman")}</Td>
                        <Td>1</Td>
                        <Td>{t("man")}</Td>
                        <Td>2</Td>
                    </Tr>
                    <Tr index={5}>
                        <Td>{t("Cadet")}</Td>
                        <Td>2009-2010</Td>
                        <Td>{t("woman")}</Td>
                        <Td>2</Td>
                        <Td>{t("man")}</Td>
                        <Td>2</Td>
                    </Tr>
                    <Tr index={6}>
                        <Td>{t("Youth")}</Td>
                        <Td>2007-2008</Td>
                        <Td>{t("woman")}</Td>
                        <Td>2</Td>
                        <Td>{t("man")}</Td>
                        <Td>3</Td>
                    </Tr>
                    <Tr index={7}>
                        <Td>{t("category.adults")}</Td>
                        <Td>{t("2006 and prior")}</Td>
                        <Td>{t("woman")}</Td>
                        <Td>2</Td>
                        <Td>{t("man")}</Td>
                        <Td>3</Td>
                    </Tr>
                    <Tr index={8}>
                        <Td>{t("Master")}</Td>
                        <Td>{t("1989 and prior")}</Td>
                        <Td>{t("woman")}</Td>
                        <Td>2</Td>
                        <Td>{t("man")}</Td>
                        <Td>3</Td>
                    </Tr>
                </Table>
            </div>
            <p className="mt-2 text-gray-600">{t("rule12")}</p>

            <h2 class="text-xl font-semibold text-gray-800 mt-6 mb-2">{t("Award")}</h2>
            <ul class="list-disc pl-4 text-gray-600">
                <li class="mt-2">{t("award1")}</li>
                <li class="mt-2">{t("award2")}</li>
            </ul>

            <h2 class="text-xl font-semibold text-gray-800 mt-6 mb-2">{t("Expenses")}</h2>
            <p class="text-gray-600 mt-2">{t("expenses.info")}</p>

            <h2 class="text-xl font-semibold text-gray-800 mt-6 mb-2">{t("Complaints")}</h2>
            <p class="text-gray-600 mt-2">{t("complaints.info")}</p>

            <h2 class="text-xl font-semibold text-gray-800 mt-6 mb-2">{t("Primary care")}</h2>
            <ul class="list-disc pl-4 text-gray-600">
                <li class="mt-2">{t("Hydration")}: {t("hydration.info")}</li>
                <li class="mt-2">{t("First aid")}: {t("firstaid.info")}</li>
            </ul>

            <h2 class="text-xl font-semibold text-gray-800 mt-6 mb-2">
                {t("Identification and documentation of participants")}
            </h2>
            <p class="text-gray-600 mt-2">{t("identification.info")}</p>
            <p class="font-medium mt-2">{t("For Mexican participants")}:</p>
            <ul class="list-disc pl-4 text-gray-600">
                <li class="mt-2">{t("formexicans1")}
                    <ul class="list-disc pl-8">
                        <li class="mt-2">{t("formexicans1a")}</li>
                        <li class="mt-2">{t("formexicans1b")}</li>
                    </ul>
                </li>
                <li class="mt-2">{t("formexicans2")}</li>
            </ul>

            <p class="font-medium mt-2">{t("For foreign participants")}:</p>
            <ul class="list-disc pl-4 text-gray-600">
                <li class="mt-2">{t("forforeign1")}
                    <ul class="list-disc pl-8">
                        <li class="mt-2">{t("forforeign1a")}</li>
                        <li class="mt-2">{t("forforeign1b")}</li>
                    </ul>
                </li>
                <li class="mt-2">{t("forforeign1c")}</li>
                <li class="mt-2">{t("forforeign1d")}</li>
                <li class="mt-2">{t("forforeign2")}</li>
            </ul>

            <h2 class="text-xl font-semibold text-gray-800 mt-6 mb-2">{t("Uniforms")}</h2>
            <p class="text-gray-600 mt-2">{t("uniforms.info")}</p>

            <h2 class="text-xl font-semibold text-gray-800 mt-6 mb-2">
                {t("Important information to consider")}
            </h2>
            <p class="text-gray-600 mt-2">{t("important.info")}</p>

            <h2 class="text-xl font-semibold text-gray-800 mt-6 mb-2">
                {t("Declaration of exclusion of responsibility")}
            </h2>
            <p class="text-gray-600 mt-2">{t("declaration.info1")}</p>
            <p class="text-gray-600 mt-2">{t("declaration.info2")}</p>
            <ul className="list-disc px-8 pb-2 text-gray-600  bg-gray-50 my-4 border rounded-md">
                <li className="mt-2">{t("declaration.info3")}</li>
            </ul>

            <p className="text-gray-600 mt-6">{t("declaration.info4")}</p>
            <p className="text-gray-600 mt-2">{t("declaration.info5")}</p>

            <ul className="list-disc px-8 pb-2 text-gray-600  bg-gray-50 my-4 border rounded-md">
                <li className="text-gray-600 mt-2">{t("declaration.info6")}</li>
                <li className="text-gray-600 mt-2">{t("declaration.info7")}</li>
                <li className="text-gray-600 mt-2">{t("declaration.info8")}</li>
                <li className="text-gray-600 mt-2">{t("declaration.info9")}</li>
                <li className="text-gray-600 mt-2">{t("declaration.info10")}</li>
                <li className="text-gray-600 mt-2">{t("declaration.info11")}</li>
                <li className="text-gray-600 mt-2">{t("declaration.info12")}</li>
                <li className="text-gray-600 mt-2">{t("declaration.info13")}</li>
                <li className="text-gray-600 mt-2">{t("declaration.info14")}</li>
                <li className="text-gray-600 mt-2">{t("declaration.info15")}</li>
            </ul>

            <h2 className="text-lg font-medium mt-6 mb-2">{t("Privacy policy")}</h2>
            <p className="text-gray-600">{t("privacy.info")}</p>

            <h2 className="text-lg font-medium mt-6 mb-2">{t("Transients")}</h2>
            <p className="text-gray-600">{t("transients.info")}</p>
        </div>
    );
}

export default TournamentPage;
