import React, { useContext, useState } from "react";
import { AuthContext } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import Button from "../components/Button";
import { useTranslation } from "react-i18next";


function LoginPage() {
  const { t } = useTranslation(); 


  const { setIsLoggedIn } = useContext(AuthContext);
  const {username, setUsername} = useContext(AuthContext);
  const { updateUserRoles } = useContext(AuthContext);
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("/api/auth/signin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          password,
        }),
      });

      const responseBody = await response.json();

      if (response.ok) {
        console.log("Login successful", responseBody);

        // Save username and token to local storage
        localStorage.setItem("username", username);
        localStorage.setItem("token", responseBody.token);
        localStorage.setItem("roles", responseBody.roles);
        localStorage.setItem("loginTime", new Date().toISOString());
        setUsername(username);
        updateUserRoles(responseBody.roles);

        setIsLoggedIn(true);
        setErrorMessage(""); // 
        navigate("/");
      }  else {
        console.log("Login failed", responseBody);

        setErrorMessage(responseBody.message); // assuming errorResponse has a message property
      }
    } catch (error) {
      console.error("Login failed", error);
      setErrorMessage(error.message);
    }
  };

  return (
    <div className="container mx-auto my-8 p-4 bg-white rounded-md shadow-lg max-w-xs">
      <div className="flex flex-col items-center gap-5">
        <h1 className="text-2xl ">{t('Login')}</h1>
        <Form onSubmit={handleSubmit} >
          <Form.Group className="login-form-group">
            <Form.Label className="login-form-label">{t('Username')}:</Form.Label>
            <Form.Control
              type="text"
              onChange={handleUsernameChange}
              className="login-form-input"
            />
          </Form.Group>

          <Form.Group className="login-form-group">
            <Form.Label className="login-form-label">{t('Password')}:</Form.Label>
            <Form.Control
              type="password"
              onChange={handlePasswordChange}
              className="login-form-input" 
            />
          </Form.Group>

          <a href="/forgotPassword" className="text-xs text-blue-600">{t('password.forgot')}</a>


          {errorMessage && (
              <p className="text-red-700 text-sm border-red-600">
                {errorMessage}
              </p>
            )}

          <div className="flow-root">
            <Button className="float-left" color="orange" type="submit">{t('Login')}</Button>
            <Button className="float-right mr-0" color="grey" to="/signup">{t('Register')}</Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default LoginPage;
