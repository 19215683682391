import { useTranslation } from "react-i18next";

function ExplorePage() {
  const { t } = useTranslation();

  return (
      <div className="container flex-row mx-auto my-8 p-8 bg-white rounded-md shadow-lg text-sm">
          <h1 className="text-2xl mb-4">{t("Explore Valles")}</h1>

          <h2 className="text-xl font-semibold text-gray-800 mb-4">{t("Accomodation")}</h2>
          <p className="text-gray-600 mb-2">{t("accomodation.info")}</p>

          <h2 className="text-xl font-semibold text-gray-800 my-4">{t("Food")}</h2>
          <p className="text-gray-600">{t("food.info")}</p>

          <h2 className="text-xl font-semibold text-gray-800 my-4">{t("Local transportation")}</h2>
          <p className="text-gray-600">{t("transport.info")}</p>

          <h2 className="text-xl font-semibold text-gray-800 my-4">{t("nature")}</h2>
          <p className="text-gray-600">{t("nature.info")}</p>

      </div>
  );
}

export default ExplorePage;
