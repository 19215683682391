import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Create the AuthContext
export const AuthContext = createContext();

// Create the AuthProvider component
export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [userRoles, setUserRoles] = useState([]);
  const navigate = useNavigate();

  const updateUserRoles = (newRoles) => {
    setUserRoles(newRoles);
  };

  const isTimeFromYesterday = (storedTime) => {
    const storedDate = new Date(storedTime);
    const currentDate = new Date();
    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);
    return storedDate < yesterday;
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    const storedUsername = localStorage.getItem('username');
    const storedTime = localStorage.getItem('loginTime');

    if (token && storedUsername) {
      if (isTimeFromYesterday(storedTime)) {
      setLoggedOut(); // Call setLoggedOut if the stored time is from yesterday
    } else {
      setIsLoggedIn(true);
      setUsername(storedUsername);
      setUserRoles(localStorage.getItem('roles'));
    }
  }
  }, []);

  const setLoggedOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('roles');
    localStorage.removeItem('loginTime');
    setIsLoggedIn(false);
    setUsername(null);
    navigate('/');
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, setLoggedOut, username, setUsername, userRoles, updateUserRoles }}>
      {children}
    </AuthContext.Provider>
  );
};