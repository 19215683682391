import React from 'react';
import { useNavigate } from 'react-router-dom';


const Button = ({ children, color, to, onClick, disabled, type, className  }) => {
    const navigate = useNavigate();
    const colorClasses = {
        orange: 'hover:bg-orange-500 bg-orange-300 text-slate-800  drop-shadow-md',
        grey: 'hover:bg-orange-200 bg-transparent text-orange-500',
  };

  const handleClick = () => {
    if (to) {
      navigate(to);
    }
  };

  return (
    <button 
      onClick={onClick ? onClick : handleClick} 
      className={`mt-4 inline-block px-3 py-1 font-medium text-xs rounded disabled:bg-opacity-20 ${colorClasses[color]}  ${className}`}
      disabled={disabled}
      type={type}>
      {children}
    </button>
  );
};

export default Button;